import React, { useState } from "react";
import "./Navbar.css";
import { Button, Container, MenuItem, Menu, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import logo from "../../Assets/logo/logo.png";
import AppoinmentButton from "../BookAppoinment/BookAnAppoinment";

import TopNavbar from "./TopNavbar";
const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  //   services menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClickServices = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TopNavbar />
      <Container
        style={{
          marginTop: 10,
        }}
        maxWidth="false"
      >
        <div className="header">
          <div className="logo-nav">
            <div className="logo-container">
              <span>
                <img className="logoimg" src={logo} />
              </span>{" "}
            </div>
          </div>
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li className="option" onClick={closeMobileMenu}>
              <a className="nav-links" href="/">
                Home
              </a>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <a className="nav-links" href="/#services">
                Services
              </a>
              {/*  <Link onClick={handleClickServices} className="nav-links" to="/">
                Services{" "}
                <ArrowDropDownIcon
                  style={{ position: "relative", top: 5 }}
                  fontSize="small"
                />
              </Link>{" "}
              <Menu
                id="fade-menu"
                MenuListProps={{
                  "aria-labelledby": "fade-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleClose}>Service 1</MenuItem>
                <MenuItem onClick={handleClose}>Service 2</MenuItem>
                <MenuItem onClick={handleClose}>Service 3</MenuItem>
              </Menu>
              */}
            </li>{" "}
            <li className="option" onClick={closeMobileMenu}>
              <a className="nav-links" href="/#about-us">
                About Us
              </a>
            </li>
            <li className="option" onClick={closeMobileMenu}>
              <a className="nav-links" href="/#projects">
                Projects{" "}
              </a>
            </li>
            <li
              className="book-an-appoinment-button"
              style={{ textDecoration: "none" }}
            >
              <AppoinmentButton variant="contained" />
            </li>
          </ul>
          <div className="mobile-view" onClick={handleClick}>
            {click ? (
              <Button fontSize="large" className="menu-icon">
                <CloseIcon color="black" />
              </Button>
            ) : (
              <Button fontSize="large" className="menu-icon">
                <ReadMoreIcon color="black" />
              </Button>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};
export default Navbar;
