import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import "./bookAppoinment.css";
import Select from "@mui/material/Select";
import bagroundimg from "../../Assets/background.jpg";
import CloseIcon from "@mui/icons-material/Close";
import emailjs from "@emailjs/browser";

import { Alert, IconButton, Snackbar } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function BookAnAppoinment(props) {
  const form = useRef();
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }
  const styles = {
    modalContainer: {
      backgroundImage: `url(${bagroundimg})`,
    },
  };
  const [formData, setFormdata] = React.useState({
    email: "",
  });
  const [branch, setBranch] = React.useState("");
  const [errorNotification, setErrorNotification] = React.useState(false);
  const [successNotification, setSuccessNotification] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(formData?.email?.length);
    if (formData.email.length) {
      emailjs
        .sendForm(
          "service_kqnjcbd",
          "template_rc7c2ne",
          form.current,
          "ChedZKreKbrfRmWYW"
        )
        .then(
          (result) => {
            console.log(result.text);
            setSuccessNotification(true);
            setOpen(false);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrorNotification(true);
    }
  };
  const textChange = (event) => {
    setFormdata({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessNotification(false);
    setErrorNotification(false);
  };
  return (
    <div>
      <Snackbar
        open={errorNotification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        TransitionComponent={SlideTransition}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity="error"
          sx={{ width: "100%" }}
        >
          Email is mandatory
        </Alert>
      </Snackbar>
      <Snackbar
        open={successNotification}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        TransitionComponent={SlideTransition}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity="success"
          sx={{ width: "100%" }}
        >
          Thank you for getting in touch!, We will contact you soon.
        </Alert>
      </Snackbar>
      <Button
        onClick={handleClickOpen}
        variant={props.variant}
        style={{ textTransform: "none", color: "white" }}
      >
        Contact Us
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontWeight: 700,
            fontSize: 30,
          }}
          color="primary"
        >
          {"Welcome !"}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon size="small" color="primary" />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <form ref={form} onSubmit={sendEmail}>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Enter your name"
                variant="outlined"
                name="name"
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <TextField
                size="small"
                id="outlined-basic"
                label=" Enter Email"
                variant="outlined"
                name="email"
                onChange={textChange}
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <TextField
                size="small"
                id="outlined-basic"
                label="Enter mobile number"
                variant="outlined"
                name="mobile"
              />
            </FormControl>{" "}
            <FormControl fullWidth style={{ marginTop: 20 }}>
              <TextField
                size="small"
                id="outlined-basic"
                label=" Discription"
                variant="outlined"
                multiline
                minRows={3}
                name="discription"
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions
          style={{
            textAlign: "center",

            backgroundImage: `url(${bagroundimg})`,
          }}
        >
          <Button
            variant={props.variant}
            style={{ textTransform: "none", color: "white" }}
            onClick={sendEmail}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
