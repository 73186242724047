import { Grid } from "@mui/material";
import logo from "../../Assets/logo/logo.png";
import WifiCallingIcon from "@mui/icons-material/WifiCalling";
import "./Footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import watsppImg from "../../Assets/whatsapp.png";
import PhoneIphoneTwoToneIcon from "@mui/icons-material/PhoneIphoneTwoTone";
const Footer = () => {
  return (
    <div className="footer-container">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid className="footer-logo" item sm={3} xs={12}>
          {" "}
          <span>
            <img className="logoimg" src={logo} />
          </span>{" "}
        </Grid>
        <Grid item sm={3} xs={12}>
          <h4>ABOUT US</h4>
          <p className="footer-para">
            Wayanad holding provide unique solution to your property related
            requirements.
          </p>
        </Grid>

        <Grid item sm={6} xs={12}>
          {" "}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid style={{ marginTop: -14 }} item sm={8} xs={12}>
              <h4>CONTACT US</h4>{" "}
              <a className="phonecall" href="tel:+91 8975999555">
                <WifiCallingIcon className="phone-icon" />
              </a>
              <a
                href="https://wa.me/+919091000916"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="whatsapp_float" src={watsppImg}></img>
              </a>
              <p className="footer-para">
                {" "}
                <PhoneIphoneTwoToneIcon
                  style={{ marginRight: 0, position: "relative", top: 5 }}
                  fontSize="small"
                />{" "}
                +91 9091000916
              </p>
              <p className="footer-para">
                <EmailTwoToneIcon
                  style={{
                    marginRight: 5,

                    color: "white",
                    position: "relative",
                    top: 6,
                  }}
                  fontSize="small"
                />{" "}
                wayanadholding@gmail.com
              </p>
            </Grid>{" "}
            <Grid item sm={4} xs={12}>
              <h4>Follow Us On</h4>
              <InstagramIcon
                fontSize="small"
                style={{ color: "white", marginRight: 6 }}
              />
              <FacebookIcon
                fontSize="small"
                style={{ color: "white", marginRight: 6 }}
              />
              <TwitterIcon
                fontSize="small"
                style={{ color: "white", marginRight: 6 }}
              />
              <LinkedInIcon fontSize="small" style={{ color: "white" }} />
            </Grid>{" "}
          </Grid>
        </Grid>
      </Grid>
      <p
        style={{
          backgroundColor: "#36BB86",
          textAlign: "center",
          marginBottom: 0,
          fontWeight: 500,
          fontSize: 12,
        }}
      >
        Wayanad holding, 2022 All rights reserved
      </p>
    </div>
  );
};
export default Footer;
