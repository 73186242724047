import * as React from "react";

import Grid from "@mui/material/Grid";
import whoweareimg from "../../Assets/whoweare.jpg";
import { Container } from "@mui/system";
export default function WhoWeAre() {
  return (
    <div className="whoweare-div">
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6}>
            <h4>WHO WE ARE</h4>
            <h1>
              We help you
              <br />
              build your dream{" "}
            </h1>
            <p>
              Wayanad Holding provides a unique solution to your
              property-related requirements. If you are looking for a gated
              community living, commercial building, an income-generating
              commercial or residential property in Wayanad, our
              property-related services will give you all the answers. Our
              up-to-the-minute skills, industry experience and dedicated team
              make maximum efforts to let your property grow. We understand the
              worth of the connection between people and their properties. This
              propels us to go that extra mile and provide highly suitable
              solutions with sufficient security for our valued clients
            </p>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <img className="whoweareimg" src={whoweareimg}></img>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
