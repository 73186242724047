import * as React from "react";
import { Button, Grid, TextField } from "@mui/material";
import "./GetInTouch.css";
import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
import BookAnAppoinment from "../BookAppoinment/BookAnAppoinment";
import { Padding } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const GetInTouch = () => {
  function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
  }
  const [email, setEmail] = React.useState("");
  const [dataNotification, setDataNotification] = React.useState({
    severity: "",
    message: "",
  });
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  const textChange = (e) => {
    setEmail(e.target.value);
  };
  const onSubmit = () => {
    const api_data = {
      email: email,
    };
  };
  const handleCloseNotification = () => {
    setNotificationOpen(false);
  };
  return (
    <>
      {" "}
      <div className="getin-touch-container">
        <Snackbar
          open={notificationOpen}
          autoHideDuration={6000}
          onClose={handleCloseNotification}
          TransitionComponent={SlideTransition}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseNotification}
            severity={dataNotification?.severity}
            sx={{ width: "100%" }}
          >
            {dataNotification?.message}
          </Alert>
        </Snackbar>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          //   spacing={4}
          className="grid-container"
        >
          <Grid item xs={12} sm={6}>
            <div className="touch">
              <h2 className="getin-head">
                Your comfort is
                <br />
                our top priority
              </h2>
              <p>
                Customer is not a disturbance to us; we are well aware about the
                fact that ultimately the customer is the reason for our
                existence. Therefore, your priorities are our priorities too.
                Come and enjoy the hospitality of Wayanad Holding Family
              </p>
              <BookAnAppoinment
                style={{ backgroundColor: "red", padding: 40 }}
                variant="contained"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default GetInTouch;
