import * as React from "react";

import Grid from "@mui/material/Grid";
import quality from "../../Assets/whychooseus/quality.png";
import agility from "../../Assets/whychooseus/agility.png";
import focus from "../../Assets/whychooseus/focus.png";
import fun from "../../Assets/whychooseus/fun.png";
import integrity from "../../Assets/whychooseus/integrity.png";
import trust from "../../Assets/whychooseus/trust.png";

import { Container } from "@mui/system";
export default function WhyChooseUs() {
  return (
    <div className="whychooseus-div">
      <Container maxWidth="lg">
        <h4 style={{ textAlign: "center" }}>WHY CHOOSE US</h4>{" "}
        <h1 style={{ textAlign: "center" }}>
          Building your dream
          <br />
          from scratch
        </h1>
        <Grid container spacing={4}>
          {" "}
          <Grid item xs={12} sm={6} md={4}>
            <div className="one-container-why-choose-us">
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={2} sm={6} md={2}>
                  <img className="why-choose-us-img" src={quality}></img>
                </Grid>
                <Grid item xs={8} sm={8} md={10}>
                  <h4>Quality</h4>
                  <p>
                    We deliver to exceed expectations of our customers in every
                    thing we do.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="one-container-why-choose-us">
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={2} sm={6} md={2}>
                  <img className="why-choose-us-img" src={integrity}></img>
                </Grid>
                <Grid item xs={8} sm={8} md={10}>
                  <h4>Integrity</h4>
                  <p>
                    We conduct ourselves in the highest ethical standards,
                    demonstrating honesty and fairness in every decision and
                    action.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="one-container-why-choose-us">
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={2} sm={6} md={2}>
                  <img className="why-choose-us-img" src={agility}></img>
                </Grid>
                <Grid item xs={8} sm={8} md={10}>
                  <h4>Agility</h4>
                  <p>We execute expeditiously to address our clients' needs.</p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="one-container-why-choose-us">
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={2} sm={6} md={2}>
                  <img className="why-choose-us-img" src={focus}></img>
                </Grid>
                <Grid item xs={8} sm={8} md={10}>
                  <h4>Customer focus</h4>
                  <p>
                    We make decisions and act in the best interests of our
                    clients, even in the face of personal or professional
                    adversity.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="one-container-why-choose-us">
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={2} sm={6} md={2}>
                  <img className="why-choose-us-img" src={trust}></img>
                </Grid>
                <Grid item xs={8} sm={8} md={10}>
                  <h4>Respect and trust</h4>
                  <p>
                    We treat our clients and each other with dignity and respect
                    at all times.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="one-container-why-choose-us">
              <Grid container spacing={2}>
                {" "}
                <Grid item xs={2} sm={6} md={2}>
                  <img className="why-choose-us-img" src={fun}></img>
                </Grid>
                <Grid item xs={8} sm={8} md={10}>
                  <h4>Fun</h4>
                  <p>
                    We believe in having fun at work for enhanced productivity.
                  </p>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
