import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import watsapp from "../../Assets/Icons/watsapp.png";
const TopNavbar = () => {
  return (
    <>
      <div className="appBarView">
        <Container maxWidth="false">
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={6}>
              {" "}
              <Typography style={{ color: "white" }} display="inline">
                {" "}
                <img
                  style={{ marginBottom: -5, marginRight: 5 }}
                  src={watsapp}
                />
                +91 9091000916{" "}
              </Typography>
              <p
                style={{
                  color: "white",
                  display: "inline",
                  marginRight: 6,
                }}
              >
                {" "}
                <MailIcon
                  style={{
                    color: "#fff",
                    marginBottom: -5,
                    marginLeft: 10,
                    marginRight: 4,
                  }}
                  fontSize="small"
                />
                wayanadholding@gmail.com
              </p>{" "}
            </Grid>
            <Grid style={{ textAlign: "right" }} item xs={6}>
              <InstagramIcon
                fontSize="small"
                style={{ color: "white", marginRight: 6 }}
              />
              <FacebookIcon
                fontSize="small"
                style={{ color: "white", marginRight: 6 }}
              />
              <TwitterIcon
                fontSize="small"
                style={{ color: "white", marginRight: 6 }}
              />
              <LinkedInIcon fontSize="small" style={{ color: "white" }} />
            </Grid>{" "}
            <hr className="divider" />
          </Grid>{" "}
        </Container>
      </div>
    </>
  );
};
export default TopNavbar;
