import * as React from "react";

import Grid from "@mui/material/Grid";
import one from "../../Assets/projects/one.png";
import two from "../../Assets/projects/two.png";
import three from "../../Assets/projects/three.png";

import { Container } from "@mui/system";
export default function OurProjects() {
  return (
    <div className="whychooseus-div">
      <Container maxWidth="lg">
        <h4>PROJECTS</h4>{" "}
        <h1>
          OUR POPULAR
          <br />
          PROJECTS
        </h1>
        <Grid container spacing={0}>
          {" "}
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <img className="ourprojects-one-img" src={one}></img>
              <h3>Builder</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <img className="ourprojects-one-img" src={two}></img>
              <h3>Developer</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div>
              <img className="ourprojects-one-img" src={three}></img>
              <h3>Joint Venture Programs</h3>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
