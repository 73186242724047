import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Config/theam";
import HomePage from "./Pages/HomePage";
import { CssBaseline } from "@mui/material";
const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HomePage />
    </ThemeProvider>
  );
};

export default App;
