import * as React from "react";

import Grid from "@mui/material/Grid";
import farmLand from "../../Assets/ourservices/farm-land.png";
import farmPlot from "../../Assets/ourservices/farm-plot.png";
import houseplot from "../../Assets/ourservices/house-plot.png";
import agriLand from "../../Assets/ourservices/agri-land.png";
import villa from "../../Assets/ourservices/villa.png";
import farmHouse from "../../Assets/ourservices/farm-house.png";

import { Container } from "@mui/system";
export default function OurServices() {
  return (
    <div className="whychooseus-div">
      <Container maxWidth="lg">
        <h4 style={{ textAlign: "center" }}>OUR SERVICES</h4>{" "}
        <h1 style={{ textAlign: "center" }}>
          Transform your dream
          <br />
          to reality
        </h1>
        <Grid container spacing={0}>
          {" "}
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ textAlign: "center" }}>
              <img className="ourservices-one-img" src={farmLand}></img>
              <h3>Farm land</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ textAlign: "center" }}>
              <img className="ourservices-one-img" src={agriLand}></img>
              <h3>Agri land</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ textAlign: "center" }}>
              <img className="ourservices-one-img" src={houseplot}></img>
              <h3>House plot</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ textAlign: "center" }}>
              <img className="ourservices-one-img" src={farmPlot}></img>
              <h3>Farm plot</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ textAlign: "center" }}>
              <img className="ourservices-one-img" src={farmHouse}></img>
              <h3>Farm house</h3>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div style={{ textAlign: "center" }}>
              <img className="ourservices-one-img" src={villa}></img>
              <h3>Villa / Home</h3>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
