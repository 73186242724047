import React from "react";
import Navbar from "../components/Navbar/Navbar";
import { Button, Grid } from "@mui/material";
import WelcomeContent from "../components/WelcomeContent";

import AppoinmentButton from "../components/BookAppoinment/BookAnAppoinment";
import hundred from "../Assets/100+.png";
import GetInTouch from "../components/GetInTouch/GetInTouch";
import Footer from "../components/Footer/Footer";
import WhoWeAre from "../components/whoweare/WhoWeAre";
import WhyChooseUs from "../components/whychooseus/WhyChooseUs";
import OurServices from "../components/ourservices/OurServices";
import OurProjects from "../components/Projects/OurProjects";

const HomePage = () => {
  return (
    <>
      <div className="dashboard">
        <div className="navbar-container">
          <Navbar />
        </div>
        <div>
          <p className="dashboardContent">
            We transform your dream <br /> into reality
          </p>
          <div className="book-an-appoinmentbutton-dash">
            <AppoinmentButton variant="contained" />
          </div>
          <div>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              <Grid item sm={8}></Grid>
              <Grid className="review-container " item sm={4}>
                {" "}
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    style={{ marginBottom: 30 }}
                    className="review"
                    item
                    xs={6}
                  >
                    <span>
                      {" "}
                      <img className="review-img" src={hundred}></img>
                    </span>
                    <span className="review-content-header"> 100+</span>
                    <br /> Projects Completed
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div id="about-us" className="whoweare-div">
        <WhoWeAre />{" "}
      </div>
      <div>
        <WhyChooseUs />{" "}
      </div>
      <div id="services">
        <OurServices />
      </div>
      <div id="projects">
        {" "}
        <OurProjects />
      </div>

      <div id="contact-us">
        <GetInTouch />
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};
export default HomePage;
